import { Avatar, List, ListItem, ListItemText, Popover } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import More from '@material-ui/icons/ArrowDropDown'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { Availability } from '../../server/Offices'
import { Reservation } from '../../server/ReservationsApi'

const useStyles = makeStyles(theme => ({
  root: {
    width: 150,
    height: 150,
    position: 'relative',
    marginLeft: '-1px',
    marginTop: '-1px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    color: theme.palette.grey[300],
    '&:hover': {
      color: theme.palette.grey[500],
      borderColor: theme.palette.grey[500],
      zIndex: 50,
    }
  },
  dayLabel: {
    fontSize: 12,
    color: theme.palette.grey[600],
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 100,
  },
  precentContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: theme.spacing(3),
    bottom: 0,
  },
  percentLabel: {
    fontSize: 12,
    color: theme.palette.grey[600],
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
  },
  percentBox: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    backgroundColor: theme.palette.success.light,
  },
  peopleButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  tinyAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    boxShadow: '1px 1px 1px 0 rgba(0, 0, 0, 0.4)',
    marginBottom: '2px',
    marginRight: '2px',
  },
  highlightAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    position: 'absolute',
    bottom: '2px',
    right: 20,
    zIndex: 200,
    boxShadow: '0px 0px 4px rgb(255, 255, 0)',
  }
}))

interface PeopleButtonOpts {
  className?: string
  reservations: Reservation[]
}

export const PeopleButton = ({reservations, className}: PeopleButtonOpts): JSX.Element => {
  const classes = useStyles()
  const theme = useTheme()

  const avatars: JSX.Element[] = []
  for (let i = 0; i < Math.min(2, reservations.length); i++) {
    avatars.push((
      <Avatar key={reservations[i].email} 
          src={reservations[i].picture} 
          alt={reservations[i].name}
          className={classes.tinyAvatar}
          style={{
            zIndex: 100 - i,
            marginLeft: -theme.spacing(2),
          }}>
        {reservations[i].name.substring(0, 1).toLocaleUpperCase()}
      </Avatar>
    ))
  }

  return (
    <div className={className}>
      {avatars}
      <More />
    </div>
  )
}

interface PeopleListOpts {
  reservations: Reservation[]
  focusEmail(email: string | undefined): void
}

const PeopleList = ({reservations, focusEmail}: PeopleListOpts): JSX.Element => {
  return (
    <List>
      {reservations.map(reservation => (
        <ListItem key={reservation.email} button
          onMouseOver={() => focusEmail(reservation.email)}
          onMouseLeave={() => focusEmail(undefined)}>
          <ListItemText>{reservation.name} </ListItemText>
        </ListItem>
      ))}
    </List>
  )
}

interface UsageCellOpts {
  day: moment.Moment
  reservations: Reservation[]
  availability: Availability
  focusEmail(email: string | undefined): void
  focusedEmail?: string
}

export const UsageCell = ({day, reservations, availability, focusEmail, focusedEmail}: UsageCellOpts): JSX.Element => {
  const percent = Math.floor(Math.min(100, reservations.length / (availability?.max_reservations || 10) * 100.0))
  const [showPeople, setShowPeople] = useState<HTMLElement | undefined>(undefined)
  const classes = useStyles()

  const focusReservation = useMemo(() => {
    return reservations.find(reservation => reservation.email === focusedEmail)
  }, [focusedEmail, reservations])

  return (
    <>
    <div className={classes.root} onClick={e => reservations.length > 0 && setShowPeople(e.currentTarget)}>
      <Typography className={classes.dayLabel}>{day.format("MMM D")}</Typography>
      {percent >= 1 && (
        <>
        <div className={classes.precentContainer}>
          <Typography
            className={classes.percentLabel}
            style={{bottom: `${percent}%`}}>
              {percent}%
          </Typography>
          <div className={classes.percentBox} style={{height: `${percent}%`}} />
        </div>
        <PeopleButton reservations={reservations} className={classes.peopleButton} />
        {focusReservation && (
          <Avatar className={classes.highlightAvatar}
            src={focusReservation.picture}
            alt={focusReservation.name}>
            {focusReservation.name.substring(0, 1).toLocaleUpperCase()}
          </Avatar>
        )}
        </>
      )}
    </div>
    <Popover
      open={Boolean(showPeople)}
      onClose={() => {
        focusEmail(undefined)
        setShowPeople(undefined)
      }}
      anchorEl={showPeople}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <PeopleList reservations={reservations}
        focusEmail={focusEmail} />
    </Popover>
    </>
  )
}
