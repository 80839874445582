import React, { useCallback, useContext, useState } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import { PrologueContext } from '../../server/PrologueProvider'

const useStyles = makeStyles(() => ({
  description: {
    fontWeight: "bold"
  },
  legal: {
    fontSize: '12px',
    color: '#444444',
    maxHeight: '200px',
    overflowY: 'scroll',
  },
}))

export default function TermsDialog (): JSX.Element {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const { acceptTandC } = useContext(PrologueContext)

  const handleAccept = useCallback(async () => {
    await acceptTandC()
    setOpen(false)
  }, [acceptTandC])

  return (
    <Dialog open={open}>
      <DialogTitle>
        Terms &amp; Conditions
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Before using HealthyOffice, please accept or decline the following after reading.
        </DialogContentText>

        <div className={classes.legal}>
        By signing up for HealthyOffice by Knotel, I acknowledge that (i) I am an authorized representative of the company; (ii) I consent to my company’s office usage and employee attendance data being processed by Knotel and shared with my Landlord (iii) upon request, Knotel may share the data with authorized representatives at my company, subject to applicable privacy laws; and (iv) I consent to receiving further marketing materials from Knotel.. Knotel does not store any health screening data from HealthyOffice. You will not be able to access any data related to the health screening questions in HealthyOffice in order to support HIPAA compliance.
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => window.location.href = 'https://www.knotel.com'}>Decline</Button>
        <Button color="primary" variant="contained" onClick={handleAccept}>Accept</Button>
      </DialogActions>
    </Dialog>
  )
}
