import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import Spinner from '../components/CenteredSpinner'

export const Login = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0()
  useEffect(() => {
    loginWithRedirect({
      appState: {
        path: location.pathname.replace('/login', '').replace('/logout', ''),
        date: new Date().getTime().toString()
      },
    })
  }, [loginWithRedirect])

  return (
    <Spinner />
  )
}

export default Login
