import React, { useCallback, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import { useAuth0 } from '@auth0/auth0-react'
import { makeStyles } from '@material-ui/core/styles'

import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import { useHistory } from 'react-router'
import { useTranslation } from "react-i18next"

import Avatar from './Avatar'
import UserProfileHelper from '../helpers/UserHelper'

const AvatarSize = 36

const useStyles = makeStyles(() => ({
  rootStyle: {
    color: 'white',
    letterSpacing: '1px',
    verticalAlign: 'middle',
    height: '36px',
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  user: {
    padding: '0px',
    width: '36px',
    height: '36px',
  },
  popper: {
    zIndex: 99999,
  }
}))

export const UserMenu = (): JSX.Element => {
  const { user } = useAuth0()
  const classes = useStyles()
  const [openAccountMenu, setOpenAccountMenu] = useState(false)
  const anchorRef = useRef(null)
  const history = useHistory()
  const { t } = useTranslation('core')

  const avatarObject = UserProfileHelper.getProfileAvatarAndLetter(user)

  const toggleAccountMenu = useCallback(() => {
    setOpenAccountMenu(!openAccountMenu)
  }, [openAccountMenu])

  const handleAddOffice = useCallback(() => {
    history.push('/addOffice')
  }, [history])

  const handleInstallSlack = () => {
    window.open('https://slack.com/oauth/v2/authorize?client_id=3993377748.1112990163044&scope=app_mentions:read,channels:history,channels:join,channels:read,chat:write,chat:write.customize,chat:write.public,groups:history,groups:read,im:history,im:read,im:write,links:read,links:write,reactions:read,reactions:write,reminders:read,reminders:write,users.profile:read,users:read,users:read.email&user_scope=',
      '_blank')
  }

  const handleLogout = useCallback(() => {
    history.push('/logout')
  }, [history])

  return user ? (
    <div className={classes.rootStyle}>
      <Button
        ref={anchorRef}
        aria-controls={openAccountMenu ? 'account-menu' : undefined}
        className={classes.user}
        onClick={toggleAccountMenu}
      >
        <Avatar
          avatarAndLetter={avatarObject}
          size={AvatarSize} />
      </Button>
      <Popper open={openAccountMenu}
        anchorEl={anchorRef.current}
        className={classes.popper}
        transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpenAccountMenu(false)}>
                  <MenuList autoFocusItem={openAccountMenu} id="account-menu">
                    <MenuItem onClick={handleAddOffice}>Add Another Office</MenuItem>
                    <MenuItem onClick={handleInstallSlack}>{"Add to your Team's Slack Channel"}</MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>{t('appbar.logout')}</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
      </Popper>
    </div>
  ) : <></>
}

export default UserMenu
