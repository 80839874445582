import { useState, useEffect } from 'react'
import { Availability } from './Offices'
import { PrologueClient, usePrologue } from './PrologueProvider'

export interface Account {
  role: string
}

export interface Customer {
  id: string
  name: string
  availabilities: Availability[]
  brivo_group_id: string
  descendants: Customer[]
  brivo_group_ids: string[]
  domains: string[]
}

export async function getCustomer (client: PrologueClient): Promise<Customer> {
  return client.get<Customer>('user/customer')
}

export async function getMainframeAccount (client: PrologueClient): Promise<Account> {
  return client.get<Account>('user/mainframeAccount')
}

export const useCustomer = (): Customer | null => {
  const client = usePrologue()
  const [customer, setCustomer] = useState<Customer | null>(null)

  useEffect(() => {
    if (!client?.authenticated) return
    getCustomer(client).then(setCustomer)
  }, [client])

  return customer
}

export const useMainframeAccount = (): Account | null => {
  const client = usePrologue()
  const [account, setAccount] = useState<Account | null>(null)

  useEffect(() => {
    if (!client) return
    getMainframeAccount(client).then(setAccount)
  }, [client])

  return account
}
