import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import ArrowBack from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/core/styles'

import UserMenu from './UserMenu'

import KnotelLogoBig from './KnotelLogoBig'
import { NavigationContext } from './NavigationContext'
import { PrologueContext } from '../../server/PrologueProvider'
import TermsDialog from '../home/TermsDialog'

const useStyles = makeStyles(theme => ({
  NavContainer: {
    backgroundColor: 'white',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexShrink: 0,
    flexGrow: 0,
    alignItems: 'center',
    justifyContent: 'start',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
    height: '60px',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
      boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.15)',
    },
    zIndex: 9999,
  },
  NavBarItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },

  NavBarAnchor: {
    fontWeight: 700,
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'underline',
    }
  },

  NavBarAnchorIcon: {
    width: '40px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '30px',
      height: '30px',
    },
    paddingRight: theme.spacing(1),
  },
}))

interface NavBarLinkOpts extends JSX.ElementChildrenAttribute {
  to: string
}

const NavBarLink = ({ children, to }: NavBarLinkOpts): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.NavBarItem}>
      <NavLink className={classes.NavBarAnchor} to={to}>{children}</NavLink>
    </div>
  )
}

export const NavBar = (): JSX.Element => {
  const classes = useStyles()
  const { backText, backPath } = useContext(NavigationContext)
  const { needsTandC } = useContext(PrologueContext)

  return (
    <div className={classes.NavContainer}>
      <NavBarLink
        to="/"
      >
        <KnotelLogoBig />
      </NavBarLink>
      {backText && (
        <NavBarLink to={backPath}>
          <ArrowBack className={classes.NavBarAnchorIcon} />
          {backText}
        </NavBarLink>
      )}
      <UserMenu />
      {needsTandC && (
        <TermsDialog />
      )}
    </div>
  )
}

export default NavBar
