import React, { useEffect, useState } from 'react'

import Typography from '@material-ui/core/Typography'
import * as Sentry from '@sentry/react'

import Spinner from '../components/CenteredSpinner'
import { usePrologue } from '../../server/PrologueProvider'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 100,
    marginLeft: 250,
    marginRight: 250,
  },
}))

export default function () {
  const prologue = usePrologue()
  const [error, setError] = useState('')
  const classes = useStyles()

  const urlParams = new URLSearchParams(location.search)
  useEffect(() => {
    prologue.post('healthyoffice/setupSlack', {
      code: urlParams.get('code')
    }).then(() => {
      setError("The slack app appears have been installed. You should have received a direct message from HealthyOffice in Slack.")
    }).catch(e => {
      Sentry.captureException(e)
      setError("We were not able to install HealthyOffice for your Team on Slack. We've recorded this error and will be able help with the problem. We are sorry this didn't work right out of the box but are eager to get this working for you.")
    })
  }, [])

  return error ? (
      <Typography className={classes.root}>{error}</Typography>
    ) : (
      <Spinner />
    )
}