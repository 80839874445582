import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  spinText: {
    fontSize: 16,
    marginTop: theme.spacing(4)
  }
}))

interface CenteredSpinnerOpts {
  text?: string
}

export default function CenteredSpinner ({ text }: CenteredSpinnerOpts): JSX.Element {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress />
      {text && (
        <Typography className={classes.spinText}>{text}</Typography>
      )}
    </div>
  )
}
