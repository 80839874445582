import { PrologueClient, CollectionListener, LiveCollection } from './PrologueProvider'

export interface SurveyResponse {
  sessionId: string
  noAnswers: string[]
}

export interface Reservation {
  email: string
  name: string
  dayNum: number
  picture?: string
  customerId: string
  availabilityId: string
  morningReminderSent?: Date
  surveys: SurveyResponse[]
  createdAt?: Date
  updatedAt?: Date
  waitlisted?: boolean
}

export async function reserve(client: PrologueClient, availabilityId: string, dayNum: number,
    sessionId: string, noAnswerIds: string[], waitlistOkay: boolean): Promise<void> {
  await client.post('/reservations', {
    availabilityId,
    dayNum,
    sessionId,
    noAnswerIds,
    waitlistOkay,
  })
}

export async function cancelReservation(client: PrologueClient, availabilityId: string, dayNum: number): Promise<void> {
  await client.delete('/reservations', {
    availabilityId,
    dayNum
  })
}

export async function surveyQuestions(client: PrologueClient, availabilityId: string): Promise<string[]> {
  return client.get<string[]>('/reservations/survey/questions', {
    availabilityId,
  })
}

export async function covidInfo(client: PrologueClient, availabilityId: string): Promise<string> {
  const result = await client.get('/reservations/survey/covidInfo', {
    availabilityId,
  })
  return result.message
}

export async function answerNo(client: PrologueClient, sessionId: string, questionId: string): Promise<string[]> {
  return client.put<string[]>('/reservations/survey/answerNo', {
    sessionId,
    questionId
  })
}

export async function getReservation(client: PrologueClient, reservationId: string): Promise<Reservation> {
  return client.get<Reservation>(`/reservations/${reservationId}`)
}

export async function getRecentReservations(client: PrologueClient, availabilityId: string, weeksAgo: number): Promise<Reservation[]> {
  return client.get<Reservation[]>('/reservations', { availabilityId, weeksAgo })
}

export async function subscribe(client: PrologueClient,
    availabilityId: string, daysFromToday: number,
    listener: CollectionListener<Reservation>): Promise<LiveCollection<Reservation>> {
  return client.subscribe<Reservation>('reservations', {
    availabilityId,
    daysFromToday,
  }, listener)
}
