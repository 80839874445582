import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  svgWrapper: {
    width: '300px',
    height: '40px',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    backgroundImage: 'url(/public/healthyofficebig.svg)',
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      height: '20px',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}))

export const KnotelLogoBig = (): JSX.Element => {
  const classes = useStyles()

  return (
      <div id="knotel-logo" className={classes.svgWrapper} />
  )
}

export default KnotelLogoBig

