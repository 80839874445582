import { User } from '@auth0/auth0-react/dist/auth-state'

export interface AvatarInfo {
  avatar?: string
  letter: string
}

const UserProfileHelper = {

  _getProfileAvatar (user: User): string | undefined {
    return user?.picture
  },

  _getProfileLetter (user: User): string {
    return `${user.given_name?.charAt(0) || ''}${user.family_name?.charAt(0) || ''}`
  },

  getProfileAvatarAndLetter (user: User): AvatarInfo {
    if (!user) return { letter: '' }
    const avatar = UserProfileHelper._getProfileAvatar(user)
    const letter = UserProfileHelper._getProfileLetter(user)
    return { avatar, letter }
  },
}

export default UserProfileHelper
