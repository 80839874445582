import React, { useEffect, useReducer, useState, useCallback, useRef } from 'react'
import moment from 'moment'
import * as Sentry from '@sentry/react'

import { usePrologue, PrologueClient } from './PrologueProvider'

export interface Region {
  name: string
  timezone: string
}

export interface Address {
  latitude: number,
  longitude: number,
}

export interface Photo {
  url: string
  smallUrl: string
}

export interface Building {
  id: string
  name: string
  spaces?: Space[]
  metro?: Region
  addresses?: Address[]
  photos?: Photo[]
  pet_policy?: string
  allows_access_24_7: boolean
  operating_hours?: string
  freight_operating_hours: string
  bike_access_type?: string
}

export interface Space {
  id: string
  availabilities?: Availability[]
  building?: Building
  building_id: string
  photos?: Photo[]
}

export interface Availability {
  id: string
  status: string
  premise: string
  building_name: string
  space?: Space
  max_reservations?: number
  reservation_days_ahead: number
  stage: string
  timezoneOffsetNow: string
  prettyPremise: string
  local_time: string
  outside_knotel?: boolean
  photos?: Photo[]
}

export interface Metro {
  timezone?: string
}

export interface OfficeContext {
  availabilities: Availability[] | undefined
  buildings: Building[]
  setMaxReservations(availabilityId: string, max: number): Promise<void>
  lookupMetro(name:string): Promise<Metro>
  addAvailability(availability:any): Promise<void>
  editAvailability(availability:any): Promise<void>
  deleteAvailability(availability:any): Promise<void>
}

const initialValue: OfficeContext = {
  availabilities: undefined,
  buildings: [],
  setMaxReservations: async () => { return },
  lookupMetro: async () => { return {} },
  addAvailability: async () => {},
  editAvailability: async () => {},
  deleteAvailability: async () => {},
}

export const OfficeContext = React.createContext(initialValue)

async function getAvailabilities(client: PrologueClient): Promise<Availability[]> {
  const result = await client.get('/availabilities')
  return result?.availabilities || []
}

function reducer(prevState: OfficeContext, action: any) {
  if (action.id === 'availabilities') {
    const newState = {
      ...prevState,
      ...action.value,
    }
    const buildingMap: {[id: string]: Building} = {}
    for (const availability of newState.availabilities) {
      const building = availability.space?.building
      if (building) {
        buildingMap[building.id] = building
      }
    }
    newState.buildings = Object.values(buildingMap)
    return newState
  }
  return prevState
}

export function OfficeProvider({ children }: JSX.ElementChildrenAttribute): JSX.Element {
  const client = usePrologue()
  const [bumpAvailabilities, setBumpAvailabilities] = useState(new Date())
  const [state, dispatch] = useReducer(reducer, initialValue)
  const availsRef = useRef<Availability[]>([])

  const setMaxReservations = useCallback(async (availabilityId: string, max: number) => {
    await client.put(`/availabilities/${availabilityId}/maxReservations`, { max })
    let newAvails = [...availsRef.current]
    newAvails = newAvails.map(thisAvail => {
      if (thisAvail.id === availabilityId) {
        return {
          ...thisAvail,
          max_reservations: max,
        }
      } else {
        return thisAvail
      }
    })

    dispatch({
      id: 'availabilities',
      value: { availabilities: newAvails }
    })
  }, [client])

  const lookupMetro = useCallback(async (name: string) => {
    return client.get(`/regions/lookupMetro/${name}`)
  }, [client])

  const addAvailability = useCallback(async (availability: any) => {
    await client.post('/availabilities', { availability })
    setBumpAvailabilities(new Date())
  }, [client])

  const editAvailability = useCallback(async (availability: any) => {
    await client.put(`/availabilities/${availability.id}`, { availability })
    setBumpAvailabilities(new Date())
  }, [client])

  const deleteAvailability = useCallback(async (availability: any) => {
    await client.delete(`/availabilities/${availability.id}`)
    setBumpAvailabilities(new Date())
  }, [client])

  useEffect(() => {
    if (!(client?.authenticated)) return

    getAvailabilities(client).then(newAvailabilities => {
      availsRef.current = newAvailabilities || []
      dispatch({
        id: 'availabilities',
        value: {
          availabilities: availsRef.current,
          setMaxReservations,
          lookupMetro,
          addAvailability,
          editAvailability,
          deleteAvailability,
        }
      })
    }).catch(e => {
      Sentry.captureException(e)
      dispatch({
        id: 'error'
      })
    })
  }, [client, bumpAvailabilities, setMaxReservations, lookupMetro, addAvailability, editAvailability, deleteAvailability])

  return (
    <OfficeContext.Provider value={state}>
      {children}
    </OfficeContext.Provider>
  )
}

export function currentTimeUTC(availability: Availability): moment.Moment {
  const time = moment.utc()
  const minuteShift = (Number(availability.timezoneOffsetNow.replace(':', '')) * 60) / 100
  return time.add(minuteShift, 'minutes')
}

export function useAvailability(availabilityId: string): Availability | undefined {
  const prologue = usePrologue()
  const [ availability, setAvailability] = useState<Availability | undefined>()

  useEffect(() => {
    if (prologue.authenticated) {
      prologue.get(`/availabilities/${availabilityId}`).then(newAvailability => {
        setAvailability(newAvailability)
      })
    }
  }, [prologue, availabilityId])

  return availability
}