import mixpanel, { Mixpanel } from 'mixpanel-browser'
import FoyerSettings from './config'

let localMixpanel: Mixpanel | undefined

if (FoyerSettings.env !== 'dev') {
  localMixpanel =
    mixpanel.init("993ec522066d6c862a7a5f20d0b87f93", {batch_requests: true})
}

export default localMixpanel
