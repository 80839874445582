import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from "@sentry/tracing"
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import LanguageDetector from 'i18next-browser-languagedetector'

import App from './App'
import FoyerSettings from './config'
import './mixpanel'

import core_en from './translations/en/core.json'
import core_fr from './translations/fr/core.json'

i18next
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    fallbackLng: 'en',
    resources: {
      en: {
        core: core_en
      },
      fr: {
        core: core_fr
      }
    },
  })

if (FoyerSettings.env !== 'dev') {
  Sentry.init({
    dsn: FoyerSettings.sentryDsn,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  })

} else {
  // tslint:disable-next-line: no-console
  console.log('ENV = development. Sentry not loaded')
}

ReactDOM.render(
  <Sentry.Profiler>
    <Sentry.ErrorBoundary>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Sentry.ErrorBoundary>
  </Sentry.Profiler>,
  document.getElementById('root'),
)
