import React, { useEffect } from 'react'
import { Route, Redirect } from "react-router"
import { useAuth0 } from '@auth0/auth0-react'
import Portal from '@material-ui/core/Portal'
import * as Sentry from '@sentry/browser'

import CenteredSpinner from "./CenteredSpinner"
import localMixpanel from '../../mixpanel'

function Telemetry () {
  const { user } = useAuth0()

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user.email,
        name: user.display_name,
      })

      localMixpanel?.people.set({
        $email: user.email,
        name: user.display_name,
      })
    }
  }, [user])

  return (
    <></>
  )
}

interface AuthRouteOpts extends JSX.ElementChildrenAttribute {
  path: string,
  exact?: boolean,
}

export default function AuthRoute ({ path, exact=false, children }: AuthRouteOpts): JSX.Element {
  const { isAuthenticated, isLoading, error, logout } = useAuth0()

  if (isLoading) {
    return (
      <Route path={path} exact={exact}>
        <>
          <CenteredSpinner text="Logging you in..." />
          <Portal>
            {children}
          </Portal>
        </>
      </Route>
    )
  } else if (isAuthenticated) {
    return (
      <>
      <Telemetry />
      <Route path={path}>
        {children}
      </Route>
      </>
    )
  } else if (error) {
    setTimeout(() => {
      logout({
        returnTo: 'https://healthyoffice.knotel.com'
      })
    }, 5000)
    return (<h1>Authentication Failed</h1>)
  } else {
    return (
      <Redirect to={`/login${location.pathname}`} />
    )
  }
}