import { makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import { useAvailability } from '../../server/Offices'
import { useRecentReservations } from '../../server/ReservationsProvider'
import { UsageCell } from './UsageCell'

interface PathParams {
  availabilityId: string
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    alignItems: 'stretch',
    alignContent: 'stretch',
    flexDirection: 'column',
    overflow: 'hidden',
    flexWrap: 'nowrap',
  },
  weekRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-end',
  },
  monthLabel: {
    fontSize: 12,
    width: 150,
    height: '100%',
    padding: theme.spacing(1),
    textDecoration: 'underline',
    color: theme.palette.grey[600],
    alignSelf: "start",
  },
  backdrop: {
    position: 'absolute',
    top: -16,
    left: -16,
    bottom: -16,
    right: -16,
    filter: 'blur(8px);',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    opacity: '50%',
  },
  cardScroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingTop: theme.spacing(4),
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
  cardStyle: {
    width: theme.spacing(114),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },
  usageTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  }
}))

export default function OfficeUsage(): JSX.Element {
  const { availabilityId } = useParams<PathParams>()
  const classes = useStyles()
  const reservations = useRecentReservations(availabilityId, 4)
  const availability = useAvailability(availabilityId)
  const [focusedEmail, focusEmail] = useState<string | undefined>()
  const photoUrl = useMemo(() => availability?.space?.building?.photos?.[0]?.url,
    [availability])

  const startCycle = moment().subtract(4, 'weeks').startOf('week')
  let lastWeekLabel = ''

  return availability ? (
    <div className={classes.root}>
      <div
        className={classes.backdrop}
        style={{
          backgroundImage: `url(${photoUrl})`,
          backgroundColor: photoUrl ? 'transparent' : '#eeeeee'
        }}>

      </div>
      <div className={classes.cardScroll}>
      <Paper className={classes.cardStyle} >
      <Typography className={classes.usageTitle}>Usage for {availability.building_name} {availability.prettyPremise}</Typography>
      {[0, 1, 2, 3].map(weekNum => {
          const thisWeek = moment(startCycle).add(weekNum, 'weeks')
          let thisWeekLabel = thisWeek.format('MMMM')
          if (thisWeekLabel === lastWeekLabel) {
            thisWeekLabel = ''
          } else {
            lastWeekLabel = thisWeekLabel
          }
          return (
            <div className={classes.weekRow} key={`week-${weekNum}`}>
              <Typography className={classes.monthLabel}>
                {thisWeekLabel}
              </Typography>
              {[1, 2, 3, 4, 5].map(dayOfWeek => {
                const day = moment(thisWeek).add(dayOfWeek, 'days')
                const dayNum = Number(day.format('YYYYMMDD'))
                return (<UsageCell key={`day-${dayNum}`}
                  availability={availability}
                  focusEmail={focusEmail}
                  focusedEmail={focusedEmail}
                  day={day}
                  reservations={reservations?.filter(res => res.dayNum === dayNum) || []}
                />)
              })}
            </div>
          )
        })}
      </Paper>
      </div>
    </div>
  ) : (<></>)
}
