import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Auth0Provider } from '@auth0/auth0-react'

import { PrologueProvider } from './server/PrologueProvider'
import Routes from './Routes'
import './App.scss'
import FoyerSettings from './config'
import NavBar from './ui/components/NavBar'
import { NavigationContextProvider } from './ui/components/NavigationContext'
import { useHistory } from 'react-router'

const theme = {
  ...createMuiTheme(),
  fontFamily: 'montserrat, sans-serif'
}

// Make sure users get updates some time after they're released but only if
// the user has been idle for a half hour
function idleTimer() {
  let t: number
  window.onmousemove = resetTimer
  window.onmousedown = resetTimer
  window.onclick = resetTimer
  window.onscroll = resetTimer
  window.onkeypress = resetTimer

  function reload() {
    if (window.location.href.indexOf('/callback') > 0) {
      window.location.href = '/'
    } else {
      window.location.href = self.location.href
    }
  }

  function resetTimer() {
    clearTimeout(t)
    t = window.setTimeout(reload, 1000 * 60 * 30)
  }

  resetTimer()
}
idleTimer()

const AuthProvider = ({ children }: JSX.ElementChildrenAttribute): JSX.Element => {
  const history = useHistory()

  return (
    <Auth0Provider
    domain={FoyerSettings.auth0domain}
    clientId={FoyerSettings.auth0clientId}
    redirectUri={FoyerSettings.callbackUrl}
    onRedirectCallback={appState => {
      if ((appState?.path?.length || 0) > 1) {
        setTimeout(() => {
          history.replace(appState.path)
        }, 10)
      }
    }}
    >
      {children}
    </Auth0Provider>
  )
}

export default function App (): JSX.Element {
  // Allow users to masquerade as a different customer. This only changes
  // what is sent in request headers. Enforcing security is done on the server.
  const asCustomerIndex = location.search.indexOf('asCustomer=')
  if (asCustomerIndex >= 0) {
    const domainStart = asCustomerIndex + 11
    let end = location.search.indexOf('&', domainStart)
    if (end < 0) end = location.search.length
    const asCustomerDomain = location.search.substr(domainStart, end - domainStart)
    localStorage.setItem('asCustomer', asCustomerDomain)
  }

  return (
    <ThemeProvider
      theme={theme}
    >
      <BrowserRouter>
        <AuthProvider>
          <PrologueProvider>
            <div style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              alignContent: 'stretch',
              width: '100%',
              height: '100%'}}>
              <NavigationContextProvider>
                <NavBar />
                <Routes />
              </NavigationContextProvider>
            </div>
          </PrologueProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}
