import React from 'react'
import MaterialAvatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import { AvatarInfo } from '../helpers/UserHelper'

const useStyles = makeStyles(() => ({
  letterAvatar: {
    position: 'relative'
  },
  urlAvatar: {
    position: 'relative'
  },
  avatarImage: {
    objectFit: 'cover',
    backgroundColor: '#221E53'
  }
}))

interface AvatarOpts {
  size: number
  avatarAndLetter: AvatarInfo
}

export default function Avatar ({ size, avatarAndLetter }: AvatarOpts): JSX.Element {
  const { avatar, letter } = avatarAndLetter
  const classes = useStyles()

  return (
    <MaterialAvatar
      src={avatar}
      className={classes.avatarImage}
      style={{
        width: size,
        height: size
      }}
    >
      {letter}
    </MaterialAvatar>
  )
}
