import prod from './config_prod.json'
import dev from './config_dev.json'

const ENV_PARAM_NAME = '__env__'

const urlParams = new URLSearchParams(location.search)
let finalConfig: { [key: string]: string; }
finalConfig = prod

const envName = urlParams.get(ENV_PARAM_NAME)
if (envName) {
  finalConfig = {}
  import(`./config_${envName.toLocaleLowerCase()}.json`).then(result => {
    Object.assign(finalConfig, result)
  })
} else if (location.host.indexOf('local') >= 0) {
  finalConfig = dev
}

export default finalConfig
