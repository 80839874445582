import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { v4 as uuidv4 } from 'uuid'

import { LiveCollection, PrologueClient, usePrologue } from './PrologueProvider'
import { Reservation, reserve, answerNo, cancelReservation, getRecentReservations } from './ReservationsApi'
import { Availability } from './Offices'

export interface SurveyQuestion {
  id: string
  text: string
}

export interface Survey {
  id: string
  questions: SurveyQuestion[]
  covidInfo: string
}

export interface ReservationsContext {
  availability?: Availability
  reservations: Reservation[]
  survey?: Survey
  reserve(dayNoment: moment.Moment, sessionId?: string, noAnswers?: string[]): Promise<void>
  waitlist(dayNoment: moment.Moment): Promise<void>
  answerNo(questionId: string): Promise<string[]>
  cancelReservation(dayMoment: moment.Moment): Promise<void>
}

const createReservationState = async (client: PrologueClient,
    availability: Availability,
    dispatch: React.Dispatch<any>): Promise<LiveCollection<Reservation>> => {

  dispatch({
    type: 'new',
    value: {
      ...initialValue,
      reserve: async (dayMoment: moment.Moment, sessionId: string, noIds: string[]) => {
        return reserve(client, availability.id, Number(dayMoment.format('YYYYMMDD')), sessionId, noIds, false)
      },
      waitlist: async (dayMoment: moment.Moment) => {
        return reserve(client, availability.id, Number(dayMoment.format('YYYYMMDD')), '', [], true)
      },
      cancelReservation: async (dayMoment: moment.Moment) => {
        return cancelReservation(client, availability.id, Number(dayMoment.format('YYYYMMDD')))
      }
    }
  })

  const subscription = await client.subscribe<Reservation>('reservations', {
    availabilityId: availability.id,
  },
  (data) => {
    dispatch({
      type: 'update',
      value: {
        reservations: data,
      }
    })
  })

    const questions = await client.get<string[]>('/reservations/survey/questions', {
      availabilityId: availability.id,
    })
    const covidInfo = (await client.get<any>('/reservations/survey/covidInfo', {
      availabilityId: availability.id,
    }))?.message
    if (questions?.length) {
      const survey: Survey = {
        id: uuidv4(),
        questions: questions.map((question): SurveyQuestion => ({
          id: uuidv4(),
          text: question,
        })),
        covidInfo
      }
      dispatch({
        type: 'update',
        value: {
          survey,
          answerNo: async (questionId: string) => {
            return answerNo(client, questionId, survey.id)
          },
        }
      })
    }

  return subscription
}

const reservationsReducer = (prevState: ReservationsContext, action: any) => {
  if (action.type === 'new') {
    return action.value
  }
  if (action.type === 'update') {
    prevState = {
      ...prevState,
      ...action.value
    }
  }
  return prevState
}

const initialValue = {
  availability: undefined,
  reservations: [],
  hasReservation: undefined,
  daysFromToday: undefined,
  dayNum: undefined,
  reserve: async () => { return },
  waitlist: async () => { return },
  cancelReservation: async () => { return },
  survey: undefined,
  covidInfo: '',
  answerNo: async () => []
}

export const ReservationsContext = React.createContext<ReservationsContext>(initialValue)

interface ReservationsProviderOpts extends JSX.ElementChildrenAttribute {
  availability: Availability
}

export function ReservationsProvider({children, availability}: ReservationsProviderOpts): JSX.Element {
  const { user } = useAuth0()
  const client = usePrologue()

  const localInit = {
    ...initialValue,
    availability,
  }

  const [state, dispatch] = useReducer(reservationsReducer, localInit)

  useEffect(() => {
    if (!client || !availability || !user) return

    let subscription: LiveCollection<Reservation> | undefined
    createReservationState(client, availability, dispatch).then(newSub => {
      subscription = newSub
    })
    return () => {
      if (subscription && client) client.unsubscribe(subscription.subscriptionId)
    }
  }, [availability, client, user])

  return (
    <ReservationsContext.Provider value={state}>
      {children}
    </ReservationsContext.Provider>
  )
}

export const useRecentReservations = (availabilityId: string, weeksAgo: number): Reservation[] => {
  const client = usePrologue()
  const [reservations, setReservations] = useState<Reservation[]>([])

  useEffect(() => {
    if (client.authenticated) {
      getRecentReservations(client, availabilityId, weeksAgo).then(newReservations => {
        setReservations(newReservations)
      })
    }
  }, [availabilityId, client, weeksAgo])

  return reservations
}

interface ReservationFromValue {
  reservations: Reservation[]
  myReservation?: Reservation
}

export const useReservationFrom = (day: moment.Moment): ReservationFromValue => {
  const { reservations } = useContext(ReservationsContext)
  const { user } = useAuth0()

  const dayNum = Number(day.format('YYYYMMDD'))
  const newReservations = reservations.filter(reservation => reservation.dayNum === dayNum)
  const myReservation = user ? newReservations.find(reservation => reservation.email === user.email) : undefined

  return {
    reservations: newReservations,
    myReservation,
  }
}
