import React, { Suspense, useContext } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Switch, Route } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'

import { OfficeProvider } from '../../server/Offices'
import CenteredSpinner from '../components/CenteredSpinner'
import { PrologueContext } from '../../server/PrologueProvider'

const OfficesMap = React.lazy(() => import(/* webpackChunkName: "healthyOfficeEmpireView" */ './EmpireView'))
const BuildingView = React.lazy(() => import(/* webpackChunkName: "healthyOfficeBuildingView" */ './BuildingView'))
const MobileOfficesView = React.lazy(() => import(/* webpackChunkName: "healthyOfficeMobile" */ './MobileOfficesView'))
const OfficeUsage = React.lazy(() => import(/* webpackChunkName: "officeUsage" */ './OfficeUsage'))

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    alignItems: 'stretch',
    alignContent: 'stretch',
    flexDirection: 'column',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    zIndex: 0,
  },
  rootError: {
    marginTop: 100,
    marginLeft: 250,
    marginRight: 250,
    textAlign: 'center',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
      marginLeft: 100,
      marginRight: 100,
      fontSize: 16,
    },
  },
  loginAgain: {
    matginTop: '30px',
  },
}))

export const Home = (): JSX.Element => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const { user } = useAuth0()
  const { customer } = useContext(PrologueContext)
  const { t } = useTranslation('core')
  const { logout } = useAuth0()

  return (
    <div className={classes.root}>
      <OfficeProvider>
          <Suspense fallback={<CenteredSpinner />}>
            {customer === null && user ? (
              <div className={classes.rootError}>
              <Typography>{t('error.customernotfound', {email: user.email})}</Typography>
              <Button className={classes.loginAgain}
                variant="contained" onClick={() => logout({ returnTo: 'https://healthyoffice.knotel.com'})}>Login Again</Button>
              </div>
            ) : (
                <Switch>
                  <Route path="/:availabilityId/usage">
                    <OfficeUsage />
                  </Route>
                  <Route path="/:buildingId/cancel/:availabilityId/:cancelDayNum">
                    <BuildingView />
                  </Route>
                  <Route path="/:buildingId/survey/:reservationId">
                    <BuildingView />
                  </Route>
                  <Route path="/:buildingId">
                    <BuildingView />
                  </Route>
                  <Route path="/">
                    {matches ? (
                      <OfficesMap />
                    ) : (
                        <MobileOfficesView />
                      )}
                  </Route>
                </Switch>
              )}
          </Suspense>
      </OfficeProvider>
    </div>
  )
}

export default Home
