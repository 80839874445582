import React, { useEffect, useReducer } from 'react'

const initialValue = {
  backText: '',
  backPath: '/',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setBack: (backText?: string, backPath?: string) => { return }
}
export const NavigationContext = React.createContext(initialValue)

const reducer = (prevState: any, action: any) => {
  if (action.type === 'update') {
    return {
      ...prevState,
      ...action.value,
    }
  }
  return prevState
}

export function NavigationContextProvider({ children }: JSX.ElementChildrenAttribute): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialValue)

  useEffect(() => {
    dispatch({
      type: 'update',
      value: {
        setBack: (backText: string, backPath: string) => {
          dispatch({
            type: 'update',
            value: {
              backText,
              backPath,
            }
          })
        }
      }
    })
  }, [])

  return (
    <NavigationContext.Provider
      value={state}>
      {children}
    </NavigationContext.Provider>
  )
}
