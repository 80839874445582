import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import UserLogin from './ui/user/Login'
import UserLogout from './ui/user/Logout'
import Home from './ui/home/Home'
import SlackCallback from './ui/slack/Callback'
import OfficeUsage from './ui/home/OfficeUsage'

import AuthRoute from './ui/components/AuthRoute'
const Onboarding = React.lazy(() => import(/* webpackChunkName: "onboarding" */ './ui/home/Onboarding'))
const AddOfficePage = React.lazy(() => import(/* webpackChunkName: "addofficepage" */ './ui/home/AddOfficePage'))

export const Routes = (): JSX.Element => {
  return (
      <Switch>
        <Route path="/login">
          <UserLogin />
        </Route>
        <Route path="/slack/callback">
          <SlackCallback />
        </Route>
        <Route path="/logout">
          <UserLogout />
        </Route>

        <Route path="/brivo" component={() => {
          window.location.href = 'https://access.knotel.com'
          return null
        }}/>
        <Route path="/reserve" component={() => {
          window.location.href = 'https://healthyoffice.knotel.com'
          return null
        }}/>

        <AuthRoute path="/usage/:availabilityId">
          <OfficeUsage />
        </AuthRoute>

        <AuthRoute path="/welcome">
          <Suspense fallback={(<></>)}>
            <Onboarding />
          </Suspense>
        </AuthRoute>

        <AuthRoute path="/addOffice">
          <Suspense fallback={(<></>)}>
            <AddOfficePage />
          </Suspense>
        </AuthRoute>

        <AuthRoute path="/">
          <Home />
        </AuthRoute>
      </Switch>
  )
}

export default Routes
