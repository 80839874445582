/* eslint-disable react/no-unescaped-entities */
import CircularProgress from '@material-ui/core/CircularProgress'
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import FoyerSettings from '../../config'

const useStyles = makeStyles(() => ({
  circularProgressContainerStyle: {
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingText: {
    fontSize: '24px'
  }
}))

export default function Logout (): JSX.Element {
  const classes = useStyles()

  const renderSpinner = (
    <div className={classes.circularProgressContainerStyle}>
      <CircularProgress
        size={80}
        thickness={5} />
      <p className={classes.loadingText}>You're being logged out</p>
    </div>
  )

  localStorage.clear()

  useEffect(() => {
    const redirectUri = encodeURI("https://knotel.com")
    window.location.href = `https://${FoyerSettings.auth0domain}/v2/logout?client_id=${FoyerSettings.auth0clientId}&returnTo=${redirectUri}`
  }, [])

  return <div style={{flex: 1}}>{renderSpinner}</div>
}
