import React, { useEffect, useContext, useReducer } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/react'

import FoyerSettings from '../config'
import { useAuth0 } from '@auth0/auth0-react'
import { v4 as uuidv4 } from 'uuid'
import { Customer, getCustomer } from './User'

export interface PrologueClient {
  authenticated: boolean
  customer?: Customer
  email?: string
  post<T = any>(pathname: string, params?: any): Promise<T>
  put<T = any>(pathname: string, params?: any): Promise<T>
  get<T = any>(pathname: string, params?: any): Promise<T>
  delete<T = any>(pathname: string, params?: any): Promise<T>
  subscribe<T = any>(publicationName: string, args: any, listener: CollectionListener<T>): Promise<LiveCollection<T>>
  unsubscribe(subscriptionId: string): void
  close(): void
}


interface SubscriptionObjectEvent<T> {
  subscriptionId: string
  action: 'update' | 'delete' | 'metadata' | 'complete'
  value?: T
}

export interface LiveCollection<T = any> {
  subscriptionId: string
  collectionName: string
  args: any
  data: T[]
  metadata: any
  onUpdate(listener: CollectionListener<T>): void
  sublistener(dataObj: SubscriptionObjectEvent<T>): void
}

export interface CollectionListener<T> {
  (data: T[], metadata: any)
}

function createLiveCollection<T>(subscriptionId: string, collectionName: string, args: any): LiveCollection<T> {
  const data: T[] = []
  const metadata: any = {}
  let listener: CollectionListener<T> | undefined
  const dataCache = {}

  return {
    subscriptionId,
    collectionName,
    args,
    sublistener(dataObj) {
      if (dataObj.action === 'metadata') {
        Object.assign(metadata, dataObj.value)
      } else if (dataObj.action === 'update') {
        dataCache[(dataObj.value as any)._id] = dataObj.value
        data.length = 0
        data.push(...Object.values(dataCache) as T[])
      } else if (dataObj.action === 'delete') {
        delete dataCache[dataObj.value as any]
        data.length = 0
        data.push(...Object.values(dataCache) as T[])
      } else {
        if (listener) listener(data, metadata)
      }
    },
    onUpdate(newListener: CollectionListener<T>) {
      listener = newListener
    },
    data,
    metadata
  }
}

export function createPrologueClient(token?: string, email?: string): PrologueClient {
  const headers: any = {}
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const asCustomer = localStorage.getItem('asCustomer')
  if (asCustomer) {
    headers['X-Knotel-As-Customer'] = asCustomer
  }

  const client = axios.create({
    baseURL: FoyerSettings.restUrl,
    headers
  })

  let wsClient: WebSocket | undefined
  let pingInterval = 0
  let reopenTimeout = 0

  let wsClientResolves: { () }[] = []
  let wsClientRejects: { () }[] = []

  const subListeners = {}

  const unsubscribe = (subscriptionId: string) => {
    wsClient?.send(JSON.stringify({
      command: 'unsubscribe',
      subscriptionId
    }))
    delete subListeners[subscriptionId]
  }

  const ensureConnected = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (!wsClient) {
        const reopenAfterDelay = () => {
          if (reopenTimeout !== 0) {
            reopenTimeout = window.setTimeout(() => {
              ensureConnected().catch(() => { return })
              reopenTimeout = 0
            }, 10000)
          }
        }
        wsClient = new WebSocket(FoyerSettings.wsUrl)
        wsClient.onopen = () => {
          wsClient?.send(JSON.stringify({ command: 'authorize', token, requestId: 'authorize', asCustomer }))
          for (const thisResolve of wsClientResolves) thisResolve()
          wsClientResolves = []
          wsClientRejects = []

          for (const live of Object.values(subListeners)) {
            sendSubscribe(live as LiveCollection)
          }
        }
        wsClient.onclose = () => {
          wsClient = undefined
          clearInterval(pingInterval)
          reopenAfterDelay()
        }
        wsClient.onerror = () => {
          wsClient?.close()
          clearInterval(pingInterval)
          for (const thisReject of wsClientRejects) thisReject()
          wsClientResolves = []
          wsClientRejects = []
          reopenAfterDelay()
        }
        wsClient.onmessage = e => {
          const dataObj = JSON.parse(e.data)
          if (dataObj.subscriptionId) {
            const listener = subListeners[dataObj.subscriptionId] as LiveCollection
            if (listener) {
              listener.sublistener(dataObj)
            } else {
              unsubscribe(dataObj.subscriptionId)
            }
          } else if (dataObj.requestId === 'authorize') {
            clearInterval(pingInterval)
            pingInterval = window.setInterval(() => {
              wsClient?.send(JSON.stringify({
                command: 'ping',
              }))
            }, 30000)
          }
        }
        wsClientResolves.push(resolve)
        wsClientRejects.push(reject)
      } else if (wsClient.readyState !== WebSocket.OPEN) {
        wsClientResolves.push(resolve)
        wsClientRejects.push(reject)
      } else {
        resolve()
      }
    })
  }

  const sendSubscribe = (collection: LiveCollection) => {
    wsClient?.send(JSON.stringify({
      command: 'subscribe',
      requestId: collection.subscriptionId,
      collection: collection.collectionName,
      args: collection.args
    }))
  }

  return {
    authenticated: Boolean(token),

    email,

    async post<T>(pathname: string, params?: any): Promise<T> {
      const resp = await client.post<T>(pathname, params)
      return resp?.data
    },

    async put<T>(pathname: string, params?: any): Promise<T> {
      const resp = await client.put<T>(pathname, params)
      return resp?.data
    },

    async get<T>(pathname: string, params?: any): Promise<T> {
      const resp = await client.get<T>(pathname, {
        params
      })
      return resp?.data
    },

    async delete<T>(pathname: string, params?: any): Promise<T> {
      const resp = await client.delete<T>(pathname, {
        params
      })
      return resp?.data
    },

    async subscribe<T>(collectionName: string, args: any, listener: CollectionListener<T>): Promise<LiveCollection<T>> {
      await ensureConnected()
      const subscriptionId = uuidv4()
      const live = createLiveCollection<T>(subscriptionId, collectionName, args)
      live.onUpdate(listener)
      subListeners[subscriptionId] = live
      sendSubscribe(live)
      return live
    },

    unsubscribe,

    close: () => {
      if (wsClient) {
        wsClient.onclose = () => { return }
        wsClient.close()
        wsClient = undefined
      }
    }
  }
}

const initialValue = {
  error: '',
  client: createPrologueClient(),
  customer: undefined,
  needsTandC: false,
  acceptTandC: async () => { }
}
export const PrologueContext = React.createContext(initialValue)

const reducer = (prevState: any, action: any) => {
  prevState.client.close()
  if (action.type === 'error') {
    return {
      client: initialValue.client,
      error: action.value,
    }
  } else if (action.type === 'client') {
    return {
      ...prevState,
      error: '',
      client: action.value,
    }
  } else if (action.type === 'customer') {
    const client = prevState.client
    client.customer = action.value
    return {
      ...prevState,
      customer: action.value,
      needsTandC: false,
    }
  } else if (action.type === 'needsTandC') {
    return {
      ...prevState,
      needsTandC: action.value,
    }
  } else if (action.type === 'update') {
    return {
      ...prevState,
      ...action.value,
    }
  }
  return prevState
}

export function PrologueProvider({ children }: JSX.ElementChildrenAttribute): JSX.Element {
  const { getAccessTokenSilently, error: auth0error, user } = useAuth0()
  const [state, dispatch] = useReducer(reducer, initialValue)

  useEffect(() => {
    if (!user || !getAccessTokenSilently) return

    let thisClient: PrologueClient | undefined
    getAccessTokenSilently({
      audience: "https://prologue.knotel.com"
    }).then(token => {
      thisClient = createPrologueClient(token, user.email)
      dispatch({ type: 'client', value: thisClient })
      getCustomer(thisClient).then(customer => {
        dispatch({ type: 'customer', value: customer })
      }).catch(e => {
        if (e?.response?.data?.name === "ConsentNotGivenError") {
          dispatch({ type: 'needsTandC', value: true })
        } else {
          Sentry.captureException(e)
          dispatch({ type: 'customer', value: null })
        }
      })
    }).catch(e => {
      Sentry.captureException(e)
      dispatch({ type: 'error', value: 'Failed to login' })
    })

    return () => thisClient?.close()
  }, [getAccessTokenSilently, user, state.needsTandC])

  useEffect(() => {
    if (auth0error) {
      dispatch({ type: 'error', value: auth0error.message })
    }
  }, [auth0error])

  useEffect(() => {
    dispatch({
      type: 'update',
      value: {
        acceptTandC: async () => {
          await state.client.put('/user/consent', { version: 'hoTC1' })
          dispatch({ type: 'needsTandC', value: false })
        }
      }
    })
  }, [state.client])

  return (
    <PrologueContext.Provider
      value={state}>
      {children}
    </PrologueContext.Provider>
  )
}

export function usePrologue(): PrologueClient {
  const { client } = useContext(PrologueContext)
  return client
}
